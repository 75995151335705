import { useWindowSize } from "@vueuse/core";

const { width } = useWindowSize();

const smBreakPoint = 640;
const mdBreakPoint = 768;
const lgBreakPoint = 1024;
const xlBreakPoint = 1280;
const xxlBreakPoint = 1280;

export default () => ({
  sm: computed(() => width.value >= smBreakPoint),
  md: computed(() => width.value >= mdBreakPoint),
  lg: computed(() => width.value >= lgBreakPoint),
  xl: computed(() => width.value >= xlBreakPoint),
  xxl: computed(() => width.value >= xxlBreakPoint),
});
